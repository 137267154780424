/* a.Nav{
  color: #ebebeb;
}

a.Nav:hover{
  text-decoration: none;
}

a.Nav:active:hover{
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
} */

.Nav.container-fluid{
  margin: 0px;
  padding: 0px
}

li.Nav-right{
  justify-content: end;
}

ul.Nav{
  display: flex;
  width: 100%;
}